import { graphql } from "gatsby"
import Layout from "../../components/layout"
import React from "react"
import SEO from "../../components/seo"

export const query = graphql`
  query LoweDoPobrania {
    allStrapiDokumentyLowe {
      edges {
        node {
          nazwa_pliku
          plik {
            url
            ext
            name
            size
            id
          }
        }
      }
    }
  }
`

function LoweDoPobrania({ data }) {
  const documents = data.allStrapiDokumentyLowe.edges.map(el => (
    <li>
      <a
        href={`${
          el.node.plik.url.startsWith("http") ? "" : process.env.STRAPI_BASE_URL
        }${el.node.plik.url}`}
        download={el.node.plik.name}
      >
        {el.node.nazwa_pliku} ({`${Math.round(el.node.plik.size)}KB `}|
        {" " + el.node.plik.ext.slice(1)})
      </a>
    </li>
  ))
  return (
    <Layout>
      <SEO title="LOWE Do pobrania - ZSP Nidek" />
      <h1>LOWE do pobrania</h1>
      <ul>
        <li>
          <a
            href="../../../documents/lowe/do_pobrania/Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.pdf"
            download="Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.pdf"
          >
            Formularz rekrutacyjny LOWE Gmina Wieprz (672KB | pdf)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/lowe/do_pobrania/Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.docx"
            download="Formularz_rekrutacyjny_LOWE_Gmina_Wieprz.docx"
          >
            Formularz rekrutacyjny LOWE Gmina Wieprz (77KB | docx)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/lowe/do_pobrania/Oswiadczenie_uczestnika_LOWE_Wieprz.pdf"
            download="Oswiadczenie_uczestnika_LOWE_Wieprz.pdf"
          >
            Oswiadczenie uczestnika LOWE Wieprz (683KB | pdf)
          </a>
        </li>
        <li>
          <a
            href="../../../documents/lowe/do_pobrania/Oswiadczenie_uczestnika_LOWE_Wieprz.docx"
            download="Oswiadczenie_uczestnika_LOWE_Wieprz.docx"
          >
            Oswiadczenie uczestnika LOWE Wieprz (76KB | docx)
          </a>
        </li>
        {documents}
      </ul>
    </Layout>
  )
}

export default LoweDoPobrania
